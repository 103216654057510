import React from "react";
import "./App.css";
import AudioPlayer from "./AudioPlayer"; // Import the audio player component
import logo from "./assets/logo.png";

const App = () => {
  return (
    <div className="container">
      <header className="header">
        <h1>Al Lubnaniya Radio</h1>
      </header>
      <main className="main-content">
        <img src={logo} alt="Al Lubnaniya Radio Logo" className="logo" />
        <h2>الإذاعة اللبنانية الدولية</h2>
        {/* Use the reusable AudioPlayer component */}
        <AudioPlayer
          src="https://lbigroup.radioca.st/;"
          title="Al Lubnaniya Radio – Live"
        />
      </main>
      <footer className="footer">
        <div>
          <h3>Al Lubnaniya Radio – Lebanon</h3>
          <p>الإذاعة اللبنانية الدولية</p>
        </div>
      </footer>
    </div>
  );
};

export default App;



