import React, { useRef, useState } from "react";
import "./AudioPlayer.css";

const AudioPlayer = ({ src, title }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="audio-player">
      <button className="play-pause-btn" onClick={togglePlayPause}>
        {isPlaying ? "⏸" : "▶"}
      </button>

      <audio
        ref={audioRef}
        src={src}
        onEnded={() => setIsPlaying(false)}
      />
    </div>
  );
};

export default AudioPlayer;
